import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import ActionPreview from '../../ActionPreview';
import { PreSelectedFromActionProps } from './PreSelectedFormAction';
import { FormRendererType, useFormRendererInfo } from '../../../../contexts/FormRendererContext';

const PreSelectedFormPreview: FC<PreSelectedFromActionProps> = (props) => {
  const { response, data } = props;
  const { question, previewQuestion, previewDescription, newFormTitle } = data;
  const client = useRecoilValue(currentClientAtom);
  const { type } = useFormRendererInfo();

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const answer = useMemo(() => {
    return type === FormRendererType.Platform ? (
      <Link to={`/clients/${client?.id}/forms/${response}/preview`} className="cursor-pointer underline">
        {newFormTitle}
      </Link>
    ) : (
      <span>{newFormTitle}</span>
    );
  }, [client?.id, newFormTitle, response, type]);

  return (
    <div>
      <ActionPreview data-cy="pre-selected-form-preview" question={title} description={previewDescription} answer={answer} />
    </div>
  );
};

export default PreSelectedFormPreview;
